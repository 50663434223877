export const getAuthState = (): { accessToken: string } | null => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        return {
            accessToken,
        };
    }
    return null;
};

export const returnToLogin = (caller?: string): void => {
    if (caller) alert(caller);
    window.location.href = '/login';
    // window.location.href = `${process.env.REACT_APP_API_URL}/login?redirect=${window.location.origin}`;
};
