import { getAuthState, returnToLogin } from '@/functions/auth';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const fetch: Function = async (options: AxiosRequestConfig, isPrivate = true) => {
    try {
        const state = getAuthState();
        if (isPrivate) {
            const token = `Bearer ${state?.accessToken}`;
            options.headers = options.headers || {};
            options.headers.Authorization = token;
            options.withCredentials = true;
            return await axios(options);
        } else {
            options.headers = options.headers || {};
            return await axios(options);
        }
    } catch (error) {
        if ((error as AxiosError).response?.status === 401 && isPrivate) {
            localStorage.removeItem('accessToken');
            returnToLogin();
        }
        throw error as AxiosError;
    }
};

export default fetch;
