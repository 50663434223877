export const capitalizeWords = (str: string): string => {
    // Split the input string into an array of words
    const words = str.split(" ");

    // Iterate through each word in the array
    for (let i = 0; i < words.length; i++) {
        if (words[i]) {
            // Capitalize the first letter of each word and concatenate it with the rest of the word
            words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
    }

    // Join the modified array of words back into a string
    return words.join(" ");
}