import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  StyledActionButtons,
  StyledDisplayName,
  StyledForm,
  StyledHandleContainer,
  StyledHandleDataContainer,
  StyledInputsContainer,
  StyledInputsRowContainer,
  StyledInstagramField,
  StyledPageContainer,
  StyledPageHeader,
  StyledProfilePictureContainer,
  StyledTabs,
  StyledTabsContent,
  StyledTabsHeader,
} from "./style";
import { ReactComponent as ProfileIcon } from "@/assets/icons/profileIcon.svg";
import PageTitle from "@/components/pageTitle";
import InputField from "@/components/inputs/inputField";
import { useTouchField } from "@/hooks/useTouchField";
import Button from "@/components/buttons";
import useUserStore from "@/store/user";
import useMinLength from "@/hooks/validation/useMinLength";
import useIsEmail from "@/hooks/validation/useIsEmail";
import {
  deleteProfilePicture,
  getBankDetails,
  getProfilePicture,
  updateBankDetails,
  updateSelf,
  uploadProfilePicture,
} from "@/communication/user";
import PictureUpload from "@/components/pictureUpload";
import { ChangeEvent } from "react";
import { FileWithPreview } from "@/types/product";
import useLoading from "@/hooks/useLoading";
import SwitchField from "@/components/inputs/switch";
import SelectField from "@/components/select";
import { getAllAgencies } from "@/communication/agencies";
import { AgencyDto } from "@/global/dtos/agency.dto";

const MyProfile: FunctionComponent = () => {
  const {
    id,
    displayName,
    firstName,
    lastName,
    email,
    instaHandle,
    tiktokHandle,
    bio,
    agencyId,
  } = useUserStore();

  type FormValues = {
    displayName: string;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    bio: string;
    profilePicture?: string;
    useAgency?: boolean;
    agencyName?: string;
    bankAccount?: string;
    routingNumber?: string;
    newBankAccount?: string;
    newRoutingNumber?: string;
    agencyId?: string;
  };

  type TmpFormValues = {
    firstName: string | undefined;
    lastName: string | undefined;
    bio: string | undefined;
    useAgency?: boolean | undefined;
    agencyName?: string | undefined;
    bankAccount?: string | undefined;
    routingNumber?: string | undefined;
    newBankAccount?: string | undefined;
    newRoutingNumber?: string | undefined;
  };

  const [formValues, setFormValues] = useState<FormValues>({
    displayName: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    bio: "",
    useAgency: false,
    agencyName: "",
    bankAccount: "",
    routingNumber: "",
    newBankAccount: "",
    newRoutingNumber: "",
    agencyId: undefined,
  });

  const [tmpFormValues, setTmpFormValues] = useState<TmpFormValues>({
    firstName: undefined,
    lastName: undefined,
    bio: undefined,
    useAgency: undefined,
    agencyName: undefined,
    bankAccount: undefined,
    routingNumber: undefined,
    newBankAccount: undefined,
    newRoutingNumber: undefined,
  });

  const [activeTab, setActiveTab] = useState(1);
  const { showLoading, hideLoading } = useLoading();
  const [agencyList, setAgencyList] = useState<AgencyDto[] | null>(null);

  const tabClick = (change: number): void => {
    setActiveTab(change);
  };

  useEffect(() => {
    if (displayName)
      setFormValues((prevValues) => ({
        ...prevValues,
        ["displayName"]: displayName,
      }));
    if (email)
      setFormValues((prevValues) => ({ ...prevValues, ["email"]: email }));
    if (firstName)
      setFormValues((prevValues) => ({
        ...prevValues,
        ["firstName"]: firstName,
      }));
    if (lastName)
      setFormValues((prevValues) => ({
        ...prevValues,
        ["lastName"]: lastName,
      }));
    if (bio) setFormValues((prevValues) => ({ ...prevValues, ["bio"]: bio }));
    if (agencyId)
      setFormValues((prevValues) => ({
        ...prevValues,
        ["agencyId"]: agencyId,
      }));
  }, [displayName, email, firstName, lastName, bio, agencyId]);

  useEffect(() => {
    showLoading();
    getProfilePicture(id)
      .then((res: any) => {
        const pictures = res.data.map((picture: any) => {
          return { id: picture.id, preview: picture.url };
        });
        setProfilePicture(pictures);
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getAllAgencies().then((res: any) => {
      setAgencyList && setAgencyList(res.data);
    });
  }, []);

  useEffect(() => {
    showLoading();
    getBankDetails()
      .then((res: any) => {
        if (res.data) {
          if (res.data?.useAgency && res.data?.agencyName) {
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["useAgency"]: true,
            }));
            setFormValues((prevValues) => ({
              ...prevValues,
              ["agencyName"]: res.data?.agencyName,
            }));
            setTmpFormValues((prevValues) => ({
              ...prevValues,
              ["useAgency"]: res.data?.agencyName,
            }));
          } else {
            setFormValues((prevValues) => ({
              ...prevValues,
              ["bankAccount"]: `*********${res.data.external_accounts.data[0].last4}`,
            }));
            setFormValues((prevValues) => ({
              ...prevValues,
              ["routingNumber"]:
                res.data.external_accounts.data[0].routing_number,
            }));
          }
        }

        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.log(error);
      });
  }, []);

  const isFormValid = true;

  const emailErrorMessage = useIsEmail(formValues.email);
  const passwordErrorMessage = useMinLength(formValues.password, 8);

  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();

  const handleChange = (fieldName: keyof FormValues, value: string): void => {
    handleFieldTouch(fieldName);
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    if (fieldName === "firstName")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["firstName"]: value,
      }));
    if (fieldName === "lastName")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["lastName"]: value,
      }));
    if (fieldName === "bio")
      setTmpFormValues((prevValues) => ({ ...prevValues, ["bio"]: value }));
    if (fieldName === "agencyName")
      setTmpFormValues((prevValues) => ({
        ...prevValues,
        ["agencyName"]: value,
      }));
  };

  const handleUpdate = (): void => {
    saveProfilePicture();
    showLoading();
    const {
      bankAccount,
      useAgency,
      newRoutingNumber,
      newBankAccount,
      ...rest
    } = tmpFormValues;
    updateSelf(rest)
      .then(async (res: any) => {
        hideLoading();
        toast.success("Profile updated!", {
          position: "bottom-center",
          autoClose: 1500,
        });

        if (displayName)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["displayName"]: res.data.displayName,
          }));
        if (email)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["email"]: res.data.email,
          }));
        if (firstName)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["firstName"]: res.data.firstName,
          }));
        if (lastName)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["lastName"]: res.data.lastName,
          }));
        if (bio)
          setFormValues((prevValues) => ({
            ...prevValues,
            ["bio"]: res.data.bio,
          }));
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  const [profilePicture, setProfilePicture] = useState<FileWithPreview[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const filesArray = Array.from(files).map((file) => {
        const preview = URL.createObjectURL(file);
        return Object.assign(file, { preview });
      });
      setProfilePicture(filesArray);
    }
  };

  const saveProfilePicture = () => {
    const formData = new FormData();
    profilePicture.forEach((file) => {
      formData.append("files", file);
    });
    uploadProfilePicture(formData)
      .then(() => {})
      .catch((err) => {});
  };

  const handleRemoveFile = (file: FileWithPreview) => {
    setProfilePicture((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
    if (file.id) {
      deleteProfilePicture(file.id)
        .then((res: any) => {})
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const updateBankInfo = (): void => {
    if (tmpFormValues.useAgency) {
      showLoading();
      updateBankDetails(tmpFormValues.agencyName)
        .then(async (res: any) => {
          toast.success("Bank info updated!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    } else if (formValues.newBankAccount && formValues.newRoutingNumber) {
      showLoading();
      updateBankDetails(
        undefined,
        formValues.newBankAccount,
        formValues.newRoutingNumber
      )
        .then(async (res: any) => {
          toast.success("Bank info updated!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          hideLoading();
        })
        .catch((error: any) => {
          hideLoading();
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
          console.log(error);
        });
    }
  };

  return (
    <div className="page">
      <StyledPageHeader>
        <PageTitle title="Profile page" icon={<ProfileIcon />} />
      </StyledPageHeader>
      <StyledPageContainer>
        <StyledTabsHeader>
          <StyledTabs
            onClick={() => {
              tabClick(1);
            }}
            className={`${activeTab === 1 ? "active" : ""}`}
          >
            Personal information
          </StyledTabs>
          <StyledTabs
            onClick={() => {
              tabClick(0);
            }}
            className={`${activeTab === 0 ? "active" : ""}`}
          >
            Storefront settings
          </StyledTabs>
          <StyledTabs
            onClick={() => {
              tabClick(2);
            }}
            className={`${activeTab === 2 ? "active" : ""}`}
          >
            Bank information
          </StyledTabs>
        </StyledTabsHeader>
        <StyledTabsContent
          className={`tab-content ${activeTab === 1 ? "activeTab" : ""}`}
        >
          <StyledForm>
            <StyledInputsContainer>
              <StyledInputsRowContainer>
                <InputField
                  type="text"
                  label="Email"
                  value={formValues.email}
                  errorMessage={
                    isFieldTouched("email") ? emailErrorMessage : ""
                  }
                  onChange={(e) => handleChange("email", e.target.value)}
                  placeholder="Email"
                />
                <InputField
                  type="text"
                  label="Password"
                  value={formValues.password}
                  errorMessage={
                    isFieldTouched("password") ? passwordErrorMessage : ""
                  }
                  onChange={(e) => handleChange("password", e.target.value)}
                  placeholder="Password"
                  disabled={true}
                />
              </StyledInputsRowContainer>
              <StyledInputsRowContainer>
                <InputField
                  type="text"
                  label="First name"
                  value={formValues.firstName}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  placeholder="First name"
                />
                <InputField
                  type="text"
                  label="lastName"
                  value={formValues.lastName}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  placeholder="Last name"
                />
              </StyledInputsRowContainer>
              <StyledActionButtons>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="outlined"
                  onClick={handleUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="solid"
                  onClick={handleUpdate}
                >
                  SAVE
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledTabsContent>

        <StyledTabsContent
          className={`tab-content ${activeTab === 0 ? "activeTab" : ""}`}
        >
          <StyledForm>
            <StyledHandleContainer>
              <StyledProfilePictureContainer>
                <PictureUpload
                  multiple={false}
                  thumbnails={false}
                  onRemove={handleRemoveFile}
                  onFileChange={handleFileChange}
                  pictureList={profilePicture}
                  showActions={true}
                  profilePic={true}
                />
              </StyledProfilePictureContainer>
              <StyledHandleDataContainer>
                <StyledInstagramField>
                  <span>Instagram HANDLE</span>
                  <span style={{ fontWeight: "bold" }}>
                    {instaHandle ?? "/"}
                  </span>
                </StyledInstagramField>
                <StyledInstagramField>
                  <span>TikTok HANDLE</span>
                  <span style={{ fontWeight: "bold" }}>
                    {tiktokHandle ?? "/"}
                  </span>
                </StyledInstagramField>
                <StyledDisplayName>
                  <InputField
                    type="text"
                    label="Display Name"
                    value={formValues.displayName}
                    onChange={(e) =>
                      handleChange("displayName", e.target.value)
                    }
                    placeholder="Display name"
                    disabled={true}
                  />
                </StyledDisplayName>
              </StyledHandleDataContainer>
            </StyledHandleContainer>
            <StyledInputsContainer>
              <InputField
                type="textarea"
                label="About me"
                value={formValues.bio}
                onChange={(e) => handleChange("bio", e.target.value)}
                placeholder="About me"
              />

              <StyledActionButtons>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="outlined"
                  onClick={handleUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  disabled={!isFormValid}
                  width="152px"
                  variant="solid"
                  onClick={handleUpdate}
                >
                  SAVE
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledTabsContent>

        <StyledTabsContent
          className={`tab-content ${activeTab === 2 ? "activeTab" : ""}`}
        >
          <StyledForm>
            <StyledInputsContainer>
              <SwitchField
                onChange={(value: boolean) => {
                  setTmpFormValues((prevValues) => ({
                    ...prevValues,
                    ["useAgency"]: value,
                  }));
                }}
                defaultChecked={tmpFormValues.useAgency || false}
                label="Using an Agency"
                disabled={true}
              />
              {tmpFormValues.useAgency ? (
                <SelectField
                  label="Agency"
                  options={agencyList?.map((agency) => {
                    return { value: agency.name, label: agency.name };
                  })}
                  onChange={(e) => handleChange("agencyName", e.value)}
                  placeholder={formValues.agencyName}
                  isDisabled={true}
                />
              ) : (
                <>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="Bank account number"
                      value={formValues.bankAccount}
                      disabled={true}
                    />
                    <InputField
                      type="text"
                      label="Bank routing number"
                      value={formValues.routingNumber}
                      disabled={true}
                    />
                  </StyledInputsRowContainer>
                  <StyledInputsRowContainer>
                    <InputField
                      type="text"
                      label="New bank account number"
                      value={formValues.newBankAccount}
                      onChange={(e) =>
                        handleChange("newBankAccount", e.target.value)
                      }
                      disabled={true}
                    />
                    <InputField
                      type="text"
                      label="New bank routing number"
                      value={formValues.newRoutingNumber}
                      onChange={(e) =>
                        handleChange("newRoutingNumber", e.target.value)
                      }
                      disabled={true}
                    />
                  </StyledInputsRowContainer>
                </>
              )}

              <StyledActionButtons>
                <Button
                  disabled={true}
                  width="100%"
                  variant="solid"
                  onClick={updateBankInfo}
                >
                  Update bank info
                </Button>
              </StyledActionButtons>
            </StyledInputsContainer>
          </StyledForm>
        </StyledTabsContent>
      </StyledPageContainer>
    </div>
  );
};
export default MyProfile;
