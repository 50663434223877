import styled from 'styled-components';

export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        padding: 0 20px;
    }
`;

export const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
`;

export const StyledTabsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap:20px;
`;

export const StyledTabs = styled.div`
    padding: 9px 20px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    background-color: ${(props) => props.theme.palette.white};
    color: ${(props) => props.theme.palette.black};
    font-size: 16px;
    border: border: 1px solid #C4C4C4;

    &.active {
        color: ${(props) => props.theme.palette.white};
        background-color: ${(props) => props.theme.palette.black};
    }
`;

export const StyledTabsContent = styled.div`
    visibility: hidden;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    &.activeTab {
        visibility: visible;
        height: auto;
        overflow: visible;
        pointer-events: all;
        width: 680px;
        @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
            width: 100%;
        }
    }
`;

export const StyledForm = styled.div`
    background: ${(props) => props.theme.palette.lightBgColor};
`;

export const StyledHandleContainer = styled.div`
    width: 680px;
    display:flex;
    align-items: center;
    background: linear-gradient(to right, #FFF5BA 0%, #FFECD2 54%, #FDEBFF 100%);
    height: 400px;
    padding: 20px;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 15px;
    }
`;

export const StyledInstagramField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 45px;
`;

export const StyledDisplayName = styled.div`
    width: 310px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 100%;
    }
`;

export const StyledProfilePictureContainer = styled.div`
    max-width: 234px;
    height: 246px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 100%;
        height: 100%;
    }
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        box-shadow: -2px 2px 5px rgba(0, 0, 0, 1);
    }
`;

export const StyledHandleDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    textarea {
        height: 160px;
    }
`;

export const StyledInputsRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const StyledActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;