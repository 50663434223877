import { FunctionComponent, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTouchField } from "@/hooks/useTouchField";
import useMinLength from "@/hooks/validation/useMinLength";
import {
  StyledAuthWrapper,
  StyledForm,
  StyledAuthContent,
  StyledImage,
} from "@/pages/login/style";
import InputField from "@/components/inputs/inputField";
import Button from "@/components/buttons";
import InfluencerImg from "@/assets/images/influencer.webp";
import { ReactComponent as KeyIcon } from "@/assets/icons/input/key.svg";
import { resetPassword } from "@/communication/auth";
import { returnToLogin } from "@/functions/auth";

type FormValues = {
  password: string;
  confirmPassword: string;
};

const CreatePassword: FunctionComponent = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    password: "",
    confirmPassword: "",
  });
  const passwordErrorMessage = useMinLength(formValues.password, 8);
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();
  const windowUrl = window.location.search;
  const urlParams = new URLSearchParams(windowUrl);
  const navigate = useNavigate();

  const handleChange = (fieldName: keyof FormValues, value): void => {
    handleFieldTouch(fieldName);
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  };

  useEffect(() => {
    const token = urlParams.get("token") ?? "";
    if (!token) returnToLogin("resetPassword");
  }, []);

  const handleResetPassword = (): void => {
    if (formValues.password !== formValues.confirmPassword)
      setPasswordMismatch(true);
    const token = urlParams.get("token") ?? "";
    resetPassword(token, formValues.password)
      .then(() => {
        toast.success("Password Created!", {
          position: "bottom-center",
          autoClose: 1500,
        });
      })
      .catch((error: any) => {
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
    navigate("/login");
    return;
  };

  useEffect(() => {
    const token = urlParams.get("token") ?? "";
    if (!token) returnToLogin("resetPassword");
  }, []);

  return (
    <StyledAuthWrapper>
      <StyledAuthContent>
        <StyledImage>
          <img src={InfluencerImg} alt="cash machine" />
        </StyledImage>
        <StyledForm>
          <h2>Create password</h2>
          <form>
            <InputField
              type="password"
              label="password"
              value={formValues.password}
              errorMessage={
                isFieldTouched("password") ? passwordErrorMessage : ""
              }
              onChange={(e) => handleChange("password", e.target.value)}
              icon={<KeyIcon />}
            />
            <InputField
              type="password"
              label="Confirm password"
              value={formValues.confirmPassword}
              errorMessage={
                isFieldTouched("confirmPassword") ? passwordErrorMessage : ""
              }
              onChange={(e) => handleChange("confirmPassword", e.target.value)}
              icon={<KeyIcon />}
            />
            <Button
              disabled={!!passwordErrorMessage || passwordMismatch}
              width="100%"
              onClick={handleResetPassword}
            >
              Create password
            </Button>
            {passwordMismatch && <div>Passwords mismatch!</div>}
          </form>
        </StyledForm>
      </StyledAuthContent>
    </StyledAuthWrapper>
  );
};
export default CreatePassword;
