import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Table from "@/components/table";
import Search from "@/components/inputs/search";
import PageTitle from "@/components/pageTitle";
import { ReactComponent as CheckIcon } from "@/assets/icons/checkIcon.svg";
import { ReactComponent as CancelIcon } from "@/assets/icons/cancelIcon.svg";
import { ReactComponent as ContentIcon } from "@/assets/icons/navbar/content.svg";
import {
  StyledActionButtonContent,
  StyledPageHeader,
  StyledTableActions,
} from "./style";
import { UserDto } from "@/global/dtos/user.dto";
import { getSellers, sellerRespond } from "@/communication/user";
import Button from "@/components/buttons";
import useLoading from "@/hooks/useLoading";

const List: FunctionComponent = () => {
  // const [searchEvent, setSearchEvent] = useState('');
  // const [searchValue, setSearchValue] = useState('');
  const [usersList, setUsersList] = useState<UserDto[] | null>(null);
  const { showLoading, hideLoading } = useLoading();

  const handleRowClick = (e): any => {
    // e.stopPropagation();
    console.log(e);
  };
  const handleValueChange = (e): void => {
    // setSearchEvent(e);
    // setSearchValue(e.target.value);
  };
  // console.log(searchEvent, searchValue);

  const handleApproval = (id: string, approval: boolean): void => {
    showLoading();
    sellerRespond(id, approval)
      .then(() => {
        getSellers("NEW").then((res: any) => {
          setUsersList && setUsersList(res.data);
          hideLoading();
          toast.success(`Application ${approval ? "approved!" : "rejected"}!`, {
            position: "bottom-center",
            autoClose: 1500,
          });
        });
      })
      .catch((error: any) => {
        hideLoading();
        toast.error("Something went wrong!", {
          position: "bottom-center",
          autoClose: 1500,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getSellers("NEW").then((res: any) => {
      setUsersList && setUsersList(res.data);
    });
  }, []);

  return (
    <div className="page">
      <StyledPageHeader>
        <PageTitle title="Seller applications" icon={<ContentIcon />} />
        <Search placeholder="Search" onChange={handleValueChange} />
      </StyledPageHeader>
      <Table
        items={
          usersList?.map((user) => ({
            id: user.id,
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            email: user.email,
            instaHandle: user.instaHandle || "",
            tiktokHandle: user.tiktokHandle || "",
            displayName: user.displayName,
          })) || []
        }
        columns={[
          { accessorKey: "firstName", header: "First name" },
          { accessorKey: "lastName", header: "Last name" },
          { accessorKey: "email", header: "Email" },
          { accessorKey: "instaHandle", header: "Instagram" },
          { accessorKey: "tiktokHandle", header: "TikTok" },
          { accessorKey: "displayName", header: "Display name" },
        ]}
        handleRowClick={(e) => handleRowClick(e)}
        customRowActions={(item) => (
          <StyledTableActions>
            <Button onClick={() => handleApproval(item.id, true)}>
              <StyledActionButtonContent>
                <CheckIcon />
                <span>Approve</span>
              </StyledActionButtonContent>
            </Button>
            <Button onClick={() => handleApproval(item.id, false)}>
              <StyledActionButtonContent>
                <CancelIcon />
                <span>Reject</span>
              </StyledActionButtonContent>
            </Button>
          </StyledTableActions>
        )}
      />
    </div>
  );
};
export default List;
