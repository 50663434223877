import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import InputFieldWrapper, { BaseInputProps } from "../inputFieldWrapper";
import { Palette } from "@/types/theme";

interface SwitchProps {
  color?: keyof Palette;
  onChange?: Function;
  label?: string | ReactNode;
  defaultChecked?: boolean;
  disabled?: boolean;
}

const SwitchField: FunctionComponent<SwitchProps> = ({
  label,
  onChange,
  defaultChecked,
  disabled,
  ...switchProps
}) => {
  const [isChecked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const change = (): void => {
    setChecked(!isChecked);
    onChange ? onChange(!isChecked) : null;
  };
  return (
    <InputFieldWrapper>
      <StyledSwitch {...switchProps}>
        <input
          disabled={disabled}
          type="checkbox"
          onChange={change}
          checked={isChecked}
          {...switchProps}
        />
        <span className="slider round"></span>
        <div className="label">{label}</div>
      </StyledSwitch>
    </InputFieldWrapper>
  );
};

SwitchField.defaultProps = {
  color: "primary",
};

const StyledSwitch = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  height: 1.5rem;
  input {
    opacity: 0;
    height: 0;
    min-width: 2.8rem;
    &:checked + .slider {
      background-color: ${(props) => props.theme.palette.black};
    }
    &:focus + .slider {
      // box-shadow: 0 0 0.125rem ${(props) =>
        props.theme.palette[props.color ?? "primary"]};
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(1rem);
      -ms-transform: translateX(1rem);
      transform: translateX(1rem);
    }
    &:disabled + .slider,
    &:disabled + .slider + .label {
      opacity: 0.5;
    }
  }
  .slider {
    position: absolute;
    width: 2.5rem;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.palette.gray};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 1rem;
      width: 1rem;
      left: 0.25rem;
      bottom: 0.25rem;
      background-color: white;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  .label {
    color: ${(props) => props.theme.palette.black};
    font-size: 14px;
  }

  &:hover {
    .checkmark,
    .label {
      opacity: 0.5;
    }
  }
`;

export default SwitchField;
