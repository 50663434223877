import styled from 'styled-components';

export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        align-items: baseline;
        padding: 0 20px;
    }
`;

export const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
`;

export const StyledForm = styled.div`
    background: ${(props) => props.theme.palette.lightBgColor};
    width: 688px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 15px;
    }
`;

export const StyledProfilePictureContainer = styled.div`
    margin-top: 20px;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`;

export const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    textarea {
        height: 160px;
    }
`;

export const StyledActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .error {
        background: ${(props) => props.theme.palette.error}
    }
`;

export const StyledSwitchContainer = styled.div`
    display: flex;
`;
