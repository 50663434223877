import fetch from './fetch';
import routes from './routes';

export const getTotalSales = async (id): Promise<number> => {
    return fetch({
        method: 'GET',
        url: routes.payments.totalSales(id),
        headers: { 'Content-Type': 'application/json' },
    });
};
