import { CreateUser } from '@/types/user';
import fetch from './fetch';
import routes from './routes';

export const getLoggedUser = async (): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.profile.my,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const registerUser = async (data: CreateUser): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.users.createUser,
        headers: { 'Content-Type': 'application/json' },
        data,
    });
};

export const getSellers = async (status: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.users.list,
        headers: { 'Content-Type': 'application/json' },
        params: { status }
    });
};

export const sellerRespond = async (id: string, approval: boolean): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.users.editSeller(id),
        headers: { 'Content-Type': 'application/json' },
        data: { approval }
    });
};

export const updateSelf = async (options: { firstName?: string; lastName?: string; properties?: object }): Promise<any> => {
    return fetch({
        method: 'PATCH',
        url: routes.profile.my,
        headers: { 'Content-Type': 'application/json' },
        data: options,
    });
}

export const getProfilePicture = async (id: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.profile.picture(id),
        headers: { 'Content-Type': 'application/json' },
    })
};

export const uploadProfilePicture = async (formData: FormData): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.profile.picture,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    });
}

export const deleteProfilePicture = async (pictureId: string): Promise<any> => {
    return fetch({
        method: 'DELETE',
        url: routes.profile.picture,
        data: { pictureId }
    });
}

export const getBankDetails = async (): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.profile.account,
        headers: { 'Content-Type': 'application/json' },
    })
};

export const updateBankDetails = async (agencyName?: string, bankAccount?: string, routingNumber?: string): Promise<any> => {
    return fetch({
        method: 'PATCH',
        url: routes.profile.account,
        headers: { 'Content-Type': 'application/json' },
        data: { agencyName, bankAccount, routingNumber },
    });
};

export const createSeller = async (data: CreateUser) => {
    return fetch({
        method: 'POST',
        url: routes.users.createSeller,
        headers: { 'Content-Type': 'application/json' },
        data,
    });
};

export const notifySeller = async (id: string) => {
    return fetch({
        method: 'POST',
        url: routes.users.notifySeller(id),
        headers: { 'Content-Type': 'application/json' },
    });
};
