import styled from 'styled-components';

export const StyledContainer = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray};
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.black};
  }
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.palette.white}
`;

export const StyledImgContainer = styled.div`
  height: 250px;
  position: relative;
//   @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
//     height: 200px;
//   }
  img {
    object-fit: cover;
    height: 250px;
    width: 100%;
  }
  span {
    position: absolute;
    color: red;
    font-weight: bold;
    font-size: 40px;
    top: 35%;
    left: 35%;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 20px;
  min-height: 270px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    gap: 0;
    padding: 10px 20px;
    min-height: 200px;
  }
  justify-content: space-between;
`;

export const StyledName = styled.div`
  // color: ${(props) => props.theme.palette.black};
  // font-size: 14px;
  // font-style: normal;
  // font-weight: 400;
  // line-height: normal;
`;

export const StyledDescription = styled.div`
  color: ${(props) => props.theme.palette.gray};
  max-height: 150px;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-height: 90px;
  }
  overflow: hidden;
`;

export const StyledPricing = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCreatorDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    img {
        height: 34px;
        width: 34px;
        border-radius: 50%; 
    }
`;

export const StyledComission = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledSoldTag = styled.div`
    background: black;
    color: white;
    border-radius: 25%;
    padding: 0 5px;
`;