import { ProductCategory, ProductCondition } from "@/types/product";

const discountTable: { [key in ProductCategory]: { [key in ProductCondition]: number } } = {
    [ProductCategory.BEAUTY]: {
        [ProductCondition.NEVER]: 30,
        [ProductCondition.ONCE]: 30,
        [ProductCondition.LIGHTLY]: 30,
    },
    [ProductCategory.CLOTHING]: {
        [ProductCondition.NEVER]: 65,
        [ProductCondition.ONCE]: 70,
        [ProductCondition.LIGHTLY]: 75,
    },
    [ProductCategory.LUXURY]: {
        [ProductCondition.NEVER]: 25,
        [ProductCondition.ONCE]: 30,
        [ProductCondition.LIGHTLY]: 35,
    },
    [ProductCategory.HOME]: {
        [ProductCondition.NEVER]: 30,
        [ProductCondition.ONCE]: 35,
        [ProductCondition.LIGHTLY]: 40,
    },
    [ProductCategory.ELECTRONICS]: {
        [ProductCondition.NEVER]: 45,
        [ProductCondition.ONCE]: 50,
        [ProductCondition.LIGHTLY]: 55,
    },
    [ProductCategory.FITNESS]: {
        [ProductCondition.NEVER]: 50,
        [ProductCondition.ONCE]: 55,
        [ProductCondition.LIGHTLY]: 60,
    },
};

export const getDiscount = (category: ProductCategory, condition: ProductCondition): number => {
    return discountTable[category][condition];
}