import fetch from './fetch';
import routes from './routes';
import { AgencyDto, CreateAgencyDto } from '@/global/dtos/agency.dto';

export const getAllAgencies = async (): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.agencies.list,
        headers: { 'Content-Type': 'application/json' },
    });
};

export const getAgency = async (id: string): Promise<any> => {
    return fetch({
        method: 'GET',
        url: routes.agencies.single(id),
        headers: { 'Content-Type': 'application/json' },
    });
};

export const createAgency = async (agency: CreateAgencyDto): Promise<AgencyDto> => {
    return fetch({
        method: 'POST',
        url: routes.agencies.list,
        headers: { 'Content-Type': 'application/json' },
        data: agency,
    });
};

export const updateAgency = async (agencyId: string, data: CreateAgencyDto): Promise<AgencyDto> => {
    return fetch({
        method: 'PATCH',
        url: routes.agencies.single(agencyId),
        headers: { 'Content-Type': 'application/json' },
        data,
    });
};

export const deleteAgency = async (agencyId: string): Promise<AgencyDto> => {
    return fetch({
        method: 'DELETE',
        url: routes.agencies.single(agencyId),
        headers: { 'Content-Type': 'application/json' },
    });
};