import { getLoggedUser } from "@/communication/user";
import useUserStore from "@/store/user";
import { getAuthState, returnToLogin } from "./auth";

const initFetch = async (): Promise<void> => {
    const state = getAuthState();
    if (state) {
        const userRes = await getLoggedUser();
        useUserStore.setState({
            id: userRes?.data.id,
            displayName: userRes?.data.displayName,
            firstName: userRes?.data.firstName,
            lastName: userRes?.data.lastName,
            email: userRes?.data.email,
            instaHandle: userRes?.data.instaHandle,
            tiktokHandle: userRes?.data.tiktokHandle,
            bio: userRes?.data.bio,
            role: userRes?.data.role,
        })
    } else {
        returnToLogin();
    }
};

export default initFetch;