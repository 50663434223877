import { FunctionComponent, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useGlobalState from "@/store/global";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { ReactComponent as MenuIcon } from "@/assets/icons/navbar/menuArrow.svg";
import { ReactComponent as UserIcon } from "@/assets/icons/navbar/user.svg";
import {
  StyledHorizontalNavbar,
  StyledUserMenu,
  StyledUserDropdown,
} from "./style";
import { logoutUser } from "@/communication/auth";
import useUserStore from "@/store/user";
import { getProfilePicture } from "@/communication/user";
import { FileWithPreview } from "@/types/product";
import { RoleCodes } from "@/global/roles";
type Props = {
  className?: string;
};
const HorizontalNavbar: FunctionComponent<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isNavbarCollapsed } = useGlobalState();
  const { id, firstName, role } = useUserStore();
  const [profilePicture, setProfilePicture] = useState<string | undefined>(
    undefined
  );

  const [dropdown, setDropdown] = useState(false);

  const handleCloseDropdown = (): void => {
    setDropdown(false);
  };

  const handleLogout = (): void => {
    logoutUser().then(() => {
      localStorage.removeItem("accessToken");
      window.location.href = document.location.origin;
    });
  };

  useEffect(() => {
    getProfilePicture(id)
      .then((res: any) => {
        if (res && res.data && res.data.length > 0) {
          setProfilePicture(res.data[0].url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useOnClickOutside(ref, handleCloseDropdown);
  return (
    <StyledHorizontalNavbar className={className ?? className}>
      <button
        className="menu-arrow"
        onClick={() =>
          useGlobalState.setState({ isNavbarCollapsed: !isNavbarCollapsed })
        }
      >
        <MenuIcon />
      </button>
      <StyledUserMenu>
        <button onClick={() => setDropdown(!dropdown)}>
          {profilePicture ? <img src={profilePicture}></img> : <UserIcon />}
        </button>
        <StyledUserDropdown className={dropdown ? "visible" : "hidden"}>
          <h4>{`Hi ${firstName}`}</h4>
          {role === RoleCodes.SELLER ? (
            <Link to="/my-profile">My profile</Link>
          ) : null}
          <Link to="/login" onClick={handleLogout}>
            Logout
          </Link>
        </StyledUserDropdown>
      </StyledUserMenu>
    </StyledHorizontalNavbar>
  );
};

export default HorizontalNavbar;
