import { FunctionComponent } from "react";
import { Link as NavLink } from "react-router-dom";
import { styled } from "styled-components";
type Props = JSX.IntrinsicElements["button"] & {
  variant?: "outlined" | "solid" | "text";
  href?: string;
  className?: string;
  disabled?: boolean;
  width?: string;
  icon?: any;
};

const Button: FunctionComponent<Props> = ({
  href,
  children,
  icon,
  ...buttonProps
}) => {
  return href ? (
    <StyledButton {...buttonProps}>
      <StyledLink to={href}>{children} </StyledLink>
    </StyledButton>
  ) : (
    <StyledButton className={`${icon ? "with-icon" : ""}`} {...buttonProps}>
      {icon && <img src={icon} alt="icon" />}
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  variant: "solid",
};

const getButtonBgColor = (
  variant: string,
  active?: "active" | ""
): string | any => {
  switch (variant) {
    case "solid":
      return active
        ? (props: any) => {
            props.theme.palette.tertiary;
          }
        : (props: any) => props.theme.palette.tertiary;
    // `linear-gradient(to right, ${props.theme.palette.tertiary} 0%,  ${props.theme.palette.secondary} 54%, ${props.theme.palette.primary} 100%)`;
    //   `linear-gradient(246deg, ${props.theme.palette.tertiary} 0.59%, ${props.theme.palette.primary} 50.69%,  ${props.theme.palette.primary} 61.38%, ${props.theme.palette.tertiary} 96.41%)`;
    case "outlined":
      return "transparent";
    case "text":
      return active
        ? `${(props: any) => props.theme.palette.color.tertiary}`
        : "white";
    default:
      return active
        ? (props: any) => props.theme.palette.tertiary
        : (props: any) =>
            `linear-gradient(246deg, ${props.theme.palette.tertiary} 0.59%, ${props.theme.palette.primary} 50.69%,  ${props.theme.palette.primary} 61.38%, ${props.theme.palette.tertiary} 96.41%)`;
  }
};

const getButtonColor = (
  variant: string,
  active?: "active" | ""
): string | any => {
  switch (variant) {
    case "solid":
      return active
        ? `${(props: any) => props.theme.palette.tertiary}`
        : "black";
    case "outlined":
      return (props: any) => props.theme.palette.black;
    case "text":
      return (props: any) => props.theme.palette.primary;
    default:
      return "black";
  }
};
const StyledButton = styled.button<Props>`
  width: ${(props) => (props.width ? props.width : "fit-content")};
  background: ${(props) => props.variant && getButtonBgColor(props.variant)};
  color: ${(props) => props.variant && getButtonColor(props.variant)};
  padding: 0.75rem 1rem;
  border: ${(props) =>
    props.variant === "outlined" || props.variant === "solid"
      ? `1px solid ${props.theme.palette.black}`
      : "none"};
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: 1.125rem;
  cursor: pointer;
  background-size: 200% 100%;
  background-position: right;
  // transition:
  //     background-size 0.5s ease-in-out,
  //     transform 0.3s,
  //     background-position 0.5s ease-in-out,
  //     border 0.3s ease-in-out;

  &:hover {
    border: ${(props) =>
      props.variant === "outlined" || props.variant === "solid"
        ? `2px solid ${props.theme.palette.black}`
        : "none"};
    // background-position: left;
  }

  &:active {
    border-color: ${(props) =>
      props.variant && getButtonColor(props.variant, "active")};
    background: ${(props) =>
      props.variant && getButtonBgColor(props.variant, "active")};
    color: ${(props) =>
      props.variant && getButtonColor(props.variant, "active")};
    transform: scale(0.95);
  }

  &.with-icon {
    .children {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;
      }
    }
  }

  &:disabled {
    transform: none;
    cursor: not-allowed;
    background: rgba(170, 170, 170, 0.5);
  }
`;

const StyledLink = styled(NavLink)`
  color: inherit;
`;
export default Button;
