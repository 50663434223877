import useGlobalState from "@/store/global";

const useLoading = (): { showLoading: (caption?: string) => void; hideLoading: () => void } => {
    return {
        showLoading: (caption?: string) => {
            useGlobalState.setState({ loadingVisible: true, loadingCaption: caption ?? null });
        },
        hideLoading: () => useGlobalState.setState({ loadingVisible: false }),
    };
};

export default useLoading;