import { LoginResponse } from '@/types/communication/auth';
import fetch from './fetch';
import routes from './routes';


export const loginUser = async ({ email, password }): Promise<LoginResponse> => {
    return fetch({
        method: 'POST',
        url: routes.auth.login,
        headers: { 'Content-Type': 'application/json' },
        data: { email, password },
    });
};

export const generateToken = async (email: string): Promise<any> => {
    return fetch({
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        url: routes.auth.forgotPassword,
        data: { email },
    });
}

export const resetPassword = async (token: string, password: string): Promise<any> => {
    return fetch({
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        url: routes.auth.resetPassword,
        params: { token },
        data: { password },
    });
}

export const logoutUser = async (): Promise<any> => {
    return fetch({
        method: 'POST',
        url: routes.auth.logout,
        headers: { 'Content-Type': 'application/json' },
    });
};

