import { MouseEvent, FunctionComponent } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { StyledDeleteButton, StyledSlide, StyledUploadForm } from "./style";
import DeleteIcon from "@/assets/icons/deleteIcon.svg";
import UploadIcon from "@/assets/icons/uploadIcon.svg";
import PlaceholderIcon from "@/assets/icons/image-placeholder.svg";
import { FileWithPreview } from "@/types/product";

type Props = JSX.IntrinsicElements["button"] & {
  onRemove: Function;
  onFileChange: React.ChangeEventHandler<HTMLInputElement>;
  pictureList?: FileWithPreview[];
  showActions: boolean;
  thumbnails?: boolean;
  multiple?: boolean;
  profilePic?: boolean;
  disabled?: boolean;
};

const PictureUpload: FunctionComponent<Props> = ({
  onRemove,
  onFileChange,
  pictureList,
  showActions = false,
  thumbnails,
  multiple,
  profilePic,
  disabled = false,
}) => {
  const fileInputClick = (
    e: MouseEvent<HTMLInputElement> & {
      target: HTMLInputElement;
    }
  ): void => {
    e.target.value = "";
  };

  return (
    <div
      style={{
        filter: disabled ? "brightness(50%)" : "brightness(100%)",
      }}
    >
      <div>
        {pictureList && pictureList.length > 0 ? (
          <Carousel
            showStatus={false}
            showIndicators={false}
            showThumbs={thumbnails}
          >
            {pictureList.map((file, index) => (
              <StyledSlide
                key={index}
                style={{
                  position: "relative",
                  width: profilePic ? "200px" : "100%",
                  height: profilePic ? "200px" : "100%",
                }}
              >
                <img
                  src={file.preview}
                  alt="Preview"
                  style={{
                    width: "100%",
                    borderRadius: profilePic ? "50%" : "none",
                  }}
                />
                {showActions && (
                  <StyledDeleteButton
                    className={profilePic ? "profile-pic" : ""}
                    onClick={() => onRemove(file)}
                  >
                    <img
                      className="delete-icon"
                      src={DeleteIcon}
                      alt="deleteIcon"
                    ></img>
                  </StyledDeleteButton>
                )}
              </StyledSlide>
            ))}
          </Carousel>
        ) : (
          <img src={PlaceholderIcon} alt="default-image" />
        )}
      </div>
      {showActions && (
        <StyledUploadForm>
          <input
            id="profile-picture-input"
            type="file"
            multiple={multiple}
            accept="image/*"
            onChange={onFileChange}
            onClick={fileInputClick}
          />
          <label htmlFor={"profile-picture-input"}>
            <img className="upload-icon" src={UploadIcon} alt="icon" />
            <p>Select images</p>
          </label>
        </StyledUploadForm>
      )}
    </div>
  );
};

export default PictureUpload;
