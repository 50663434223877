export type Product = {
    id: number;
    name: string;
    description: string;
    price: string;
    properties: object;
};

export interface FileWithPreview extends File {
    preview: string;
    id?: string;
};

export enum ProductCategory {
    BEAUTY = "BEAUTY",
    CLOTHING = "CLOTHING",
    LUXURY = "LUXURY",
    HOME = "HOME",
    ELECTRONICS = "ELECTRONICS",
    FITNESS = "FITNESS",
};

export enum ProductCondition {
    NEVER = "NEVER",
    ONCE = "ONCE",
    LIGHTLY = "LIGHTLY",
};