import clsx from "clsx";
import { FunctionComponent } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import { styled } from "styled-components";
import InputFieldWrapper, { BaseInputProps } from "../inputs/inputFieldWrapper";

type Props = ReactSelectProps & BaseInputProps;

export type SelectOptionType = {
  value: string;
  label: string;
  icon?: string;
} & {
  [key: string]: any;
};

const SelectField: FunctionComponent<Props> = ({
  helperText,
  errorMessage,
  label,
  className,
  classNamePrefix,
  placeholder,
  isClearable,
  isDisabled,
  value,
  ...reactSelectProps
}) => {
  return (
    <SelectFieldWrapper
      helperText={helperText}
      errorMessage={errorMessage}
      label={label}
    >
      <ReactSelect
        isDisabled={isDisabled}
        className={clsx("react-select", className)}
        classNamePrefix={clsx("react-select", classNamePrefix)}
        placeholder={placeholder}
        isClearable={isClearable}
        {...reactSelectProps}
      />
    </SelectFieldWrapper>
  );
};

const SelectFieldWrapper = styled(InputFieldWrapper)`
  .react-select__control {
    border-color: ${(props) => props.theme.palette.gray};
    border-radius: 0.5rem;
    min-height: 2.5rem;
  }
  .react-select__option--is-selected {
    background-color: ${(props) => props.theme.palette.black};
  }
`;

export default SelectField;
