import { ReactComponent as DashboardIcon } from '@/assets/icons/navbar/dashboard.svg';
import { ReactComponent as ProfileIcon } from '@/assets/icons/profileIcon.svg';
import { ReactComponent as ContentIcon } from '@/assets/icons/navbar/content.svg';
import { ReactComponent as UsersIcon } from '@/assets/icons/usersIcon.svg';
import { ReactComponent as AgencyIcon } from '@/assets/icons/agencyIcon.svg';
import { NavbarLink } from '@/types/navbar';
import { RoleCodes } from '@/global/roles';
export const useNavbarItems = (): Array<NavbarLink> => {

    return [
        {
            title: "Applicants",
            link: '/',
            icon: DashboardIcon,
            excludeForRoles: [RoleCodes.SELLER]
        },
        {
            title: "Profile",
            link: '/my-profile',
            icon: ProfileIcon,
            excludeForRoles: [RoleCodes.ADMIN]
        },
        {
            title: "Products",
            link: '/products',
            icon: ContentIcon,
        },
        {
            title: "Agencies",
            link: '/agencies',
            icon: AgencyIcon,
            excludeForRoles: [RoleCodes.SELLER]
        },
        {
            title: "Creators",
            link: '/creators',
            icon: UsersIcon,
            excludeForRoles: [RoleCodes.SELLER]
        },
    ];
};
