import styled from 'styled-components';
import Button from '../buttons';

export const StyledSlide = styled.div`
    .carousel {
        margin-top: -33px;
    }

   
    .profile-picture: {
        border-radius: 50%;
    }
    
    
`;


export const StyledUploadForm = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px;
    gap: 20px;

    input[type='file'] {
        display: none;
    }
    label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .upload-icon {
        width: 30px;
        height: 30px;
        box-shadow: none;
    }
`;

export const StyledDeleteButton = styled(Button)`
    background: transparent;
    position: absolute;
    top: 20px;
    left: 20px;

    &.profile-pic {
        top: 1px;
        left: 1px;
    }

    .delete-icon {
        box-shadow: none;
    }
`;
