import styled from 'styled-components';

export const StyledAuthWrapper = styled.div`
    background: ${(props) => props.theme.palette.darkBgColor};
    padding: 2rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 7.5rem;
    }
    h2 {
        margin-bottom: 1.5rem;
    }
`;
export const StyledAuthContent = styled.div`
    display: flex;
    align-items: center;
    height: 30rem;
    width: 100%;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        height: 40rem;
        max-width: 74rem;
    }
    > div {
        width: 100%;
        height: 100%;
        @media (min-width: ${(props) => props.theme.breakpoints.md}) {
            width: 50%;
        }
    }
`;
export const StyledImage = styled.div`
    // border-radius: 1.25rem 0rem 0rem 1.25rem;
    position: relative;
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        display: block;
    }
    &:after {
        content: '';
        background: linear-gradient(to right, #FFF5BA 0%,  #FFECD2 54%, #FDEBFF 100%);
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        top: 0;
        mix-blend-mode: multiply;
        // border-radius: 1.25rem 0rem 0rem 1.25rem;
    }

    object-fit: cover;
    img {
        filter: grayscale(100%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        // border-radius: 1.25rem 0rem 0rem 1.25rem;
    }
`;

export const StyledBanner = styled.div`
    height: 195px;
    width: 308px;
    position: absolute;
    position: absolute;
    z-index: 1;
    color: #FFF;
    font-family: Assistant;
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px; 
    margin: 16px;
`;

export const StyledLogo = styled.img`
    width: 97px;
    height: 21px;
    position: absolute;
    top: 32px;
    right: 92px;
`;

export const StyledForm = styled.div`
    position: relative;
    background: ${(props) => props.theme.palette.lightBgColor};
    padding: 2rem;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
    // border-radius: 1.25rem;
    @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
        padding: 9.69rem 5.62rem;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        // border-radius: 0rem 1.25rem 1.25rem 0rem;
    }
`;
export const StyledForgotLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
`;

export const StyledRegister = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledRegisterLink = styled.div`
    display: flex;
`;

export const StyledWarrning = styled.div`
    font-size: 17px;
    background: #FFF5BA;
    font-weight: 600;
`;