import { FunctionComponent } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RouteContent from "./routes";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import useGlobalState from "@/store/global";
import isPropValid from "@emotion/is-prop-valid";
import { theme } from "@/global/theme";
import { getAuthState } from "./functions/auth";
import Loading from "@/components/loading";

const App: FunctionComponent = () => {
  const { isNavbarCollapsed } = useGlobalState();
  const state = getAuthState();
  const userIsLoggedIn = !!state;

  return (
    <ThemeProvider theme={theme}>
      <StyleSheetManager shouldForwardProp={isPropValid}>
        <BrowserRouter>
          <div
            className={`app ${isNavbarCollapsed ? "nav-menu-collapsed" : ""}`.trim()}
          >
            <RouteContent />
          </div>
          <Loading />
          <ToastContainer />
        </BrowserRouter>
      </StyleSheetManager>
    </ThemeProvider>
  );
};

export default App;
