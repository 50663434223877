import styled from 'styled-components';
export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledTableActions = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
`;
 export const StyledActionButtonContent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        font-size: 15px;
    }
 `;