import styled from 'styled-components';
export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledForm = styled.div`
    background: ${(props) => props.theme.palette.lightBgColor};
`;

export const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    textarea {
        height: 160px;
    }
`;

export const StyledInputsRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const StyledActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const StyledHeaderButtons = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
       justify-content: space-between;
       width: 100%;
    }
`;

export const StyledTableActions = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
`;
 export const StyledActionButtonContent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        font-size: 15px;
    }
    
 `;

 export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;