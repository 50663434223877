import { create } from 'zustand';

type GlobalState = {
    isNavbarCollapsed: boolean;
    loadingVisible: boolean;
    loadingCaption: string | null;
};

const useGlobalState = create<GlobalState>((_set) => ({
    isNavbarCollapsed: false,
    loadingVisible: false,
    loadingCaption: null,
}));

export default useGlobalState;
