import styled from 'styled-components';

export const StyledPageHeader = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        align-items: baseline;
        padding: 0 20px;
        flex-direction: column;
        .search {
            width: 100%
        }
    }
`;

export const StyledFiltering = styled.div`
    display: flex;
    gap: 10px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        flex-direction: column;
    }
`;

export const StyledHeaderButtons = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
       justify-content: space-between;
       width: 100%;
    }
`;

export const StyledProductList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    gap: 20px;
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        margin: 0 7px;
        grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
        gap: 7px;
    }
`;

export const StyledProductCartContainer = styled.div`
    cursor: pointer;
`;

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledSwitchContainer = styled.div`
    display: flex;
`;

export const StyledStatsContainer = styled.div`
    display: flex;
    padding: 20px;
    background: ${(props) => props.theme.palette.white}
    border: 1px solid ${(props) => props.theme.palette.gray};
    margin: 20px 0;
    gap: 20px;
`;

export const StyledPaginationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
`;