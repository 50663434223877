import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { useTouchField } from '@/hooks/useTouchField';
import useIsEmail from '@/hooks/validation/useIsEmail';
import { StyledAuthWrapper, StyledForm, StyledAuthContent, StyledImage } from '@/pages/login/style';
import InputField from '@/components/inputs/inputField';
import Button from '@/components/buttons';
import InfluencerImg from '@/assets/images/influencer.webp';
import { generateToken } from '@/communication/auth';

type FormValues = {
    email: string;
};

const ForgotPassword: FunctionComponent = () => {

    const [formValues, setFormValues] = useState<FormValues>({
        email: '',
    });
    const emailErrorMessage = useIsEmail(formValues.email);

    const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();

    const handleChange = (fieldName: keyof FormValues, value): void => {
        handleFieldTouch(fieldName);
        setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    };
    const handleResetPassword = (): void => {
        generateToken(formValues.email)
        .then(() => {
            toast.success("Email sent! Check your inbox.", {
                position: "bottom-center",
                autoClose: 1500,
            });
        })
        .catch(() => {
            toast.error("Something went wrong!", {
                position: "bottom-center",
                autoClose: 1500,
            });
        });
    };

    return (
        <StyledAuthWrapper>
            <StyledAuthContent>
                <StyledImage>
                    <img src={InfluencerImg} width='100%' height='250px' alt="profile pcture" />
                </StyledImage>
                <StyledForm>
                    <h2>ForgotPassword</h2>
                    <form>
                        <InputField
                            type="text"
                            label="email"
                            value={formValues.email}
                            errorMessage={isFieldTouched('email') ? emailErrorMessage : ''}
                            onChange={(e) => handleChange('email', e.target.value)}
                        />
                        <Button disabled={!!emailErrorMessage} width="100%" onClick={handleResetPassword}>
                            Reset password
                        </Button>
                    </form>
                </StyledForm>
            </StyledAuthContent>
        </StyledAuthWrapper>
    );
};
export default ForgotPassword;
