import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "@/components/inputs/inputField";
import {
  StyledAuthContent,
  StyledAuthWrapper,
  StyledBanner,
  StyledForgotLink,
  StyledForm,
  StyledImage,
  StyledLogo,
  StyledRegister,
  StyledRegisterLink,
  StyledWarrning,
} from "./style";
import { useTouchField } from "@/hooks/useTouchField";
import useIsEmail from "@/hooks/validation/useIsEmail";
import { ReactComponent as PersonIcon } from "@/assets/icons/input/person.svg";
import { ReactComponent as KeyIcon } from "@/assets/icons/input/key.svg";
import useMinLength from "@/hooks/validation/useMinLength";
import Button from "@/components/buttons";
import InfluencerImg from "@/assets/images/influencer.webp";
import { loginUser } from "@/communication/auth";
import LogoImg from "@/assets/images/logo.png";
import useUserStore from "@/store/user";

type FormValues = {
  email: string;
  password: string;
};

const Login: FunctionComponent = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    password: "",
  });

  const { role, email } = useUserStore();
  const navigate = useNavigate();
  const emailErrorMessage = useIsEmail(formValues.email);
  const passwordErrorMessage = useMinLength(formValues.password, 8);
  const isFormValid = !(emailErrorMessage || passwordErrorMessage);
  const [submitted, setSubmitted] = useState(false);

  const { isFieldTouched, handleFieldTouch } = useTouchField<FormValues>();

  const handleChange = (fieldName: keyof FormValues, value): void => {
    handleFieldTouch(fieldName);
    setFormValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
    setSubmitted(false);
  };
  const handleLogin = (): void => {
    loginUser(formValues)
      .then(async (res: any) => {
        localStorage.setItem("accessToken", res?.data?.accessToken);
        navigate("/products");
      })
      .catch((error: any) => {
        if (JSON.parse(error.request.status) === 404) {
          toast.error("Invalid credentials!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        } else if (JSON.parse(error.request.response).code === 3006) {
          setSubmitted(true);
        } else {
          toast.error("Something went wrong!", {
            position: "bottom-center",
            autoClose: 1500,
          });
        }
        console.log(error);
      });
  };

  return (
    <StyledAuthWrapper>
      <StyledAuthContent>
        <StyledImage>
          <StyledBanner>Let the Journey Begin!</StyledBanner>
          <img src={InfluencerImg} alt="Influencer image" />
        </StyledImage>
        <StyledForm>
          <StyledLogo src={LogoImg} alt="Logo" />
          <h2>Login</h2>
          <InputField
            type="text"
            label="Email"
            value={formValues.email}
            errorMessage={
              formValues.email !== "" && isFieldTouched("email")
                ? emailErrorMessage
                : ""
            }
            onChange={(e) => handleChange("email", e.target.value)}
            placeholder="Email"
            icon={<PersonIcon />}
          />
          <InputField
            type="password"
            label="Password"
            value={formValues.password}
            errorMessage={
              formValues.password !== "" && isFieldTouched("password")
                ? passwordErrorMessage
                : ""
            }
            onChange={(e) => handleChange("password", e.target.value)}
            placeholder="Password"
            icon={<KeyIcon />}
          />
          <StyledForgotLink>
            <Link to="/forgot-password">Forgot password?</Link>
          </StyledForgotLink>
          {submitted ? (
            <StyledWarrning>
              Your application is being processed. You will be notified by an
              email when the process is done. Thank you for your patience.
            </StyledWarrning>
          ) : (
            <StyledRegister>
              <StyledRegisterLink>
                <span>
                  Don't have an account?{" "}
                  <Link style={{ fontWeight: "600" }} to="/register">
                    Register here
                  </Link>
                </span>
              </StyledRegisterLink>
              <div>
                <Button
                  disabled={!isFormValid}
                  width="100%"
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </div>
            </StyledRegister>
          )}
        </StyledForm>
      </StyledAuthContent>
    </StyledAuthWrapper>
  );
};

export default Login;
